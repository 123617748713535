import theme from '@/theme'
import React from 'react'
import { SizeProps } from '../CustomModal'

interface IconProps {
    size?: SizeProps['size']
    color?: string
    rotate?: string
}

const configStyle = (props?: IconProps) => {
    const styles = {
        fontSize: props?.size || 18,
        color: props?.color || '#000',
        rotate: props?.rotate || '',
    }
    return styles
}

export const ClearIcon = (props?: IconProps) => (
    <span style={configStyle(props)} className="material-icons-round">
        clear
    </span>
)

export const SubmitIcon = (props?: IconProps) => (
    <span style={configStyle(props)} className="material-icons-round">
        check
    </span>
)

export const AddIcon = (props?: IconProps) => (
    <span style={configStyle(props)} className="material-icons-round">
        add
    </span>
)

export const EditIcon = (props?: IconProps) => (
    <span style={configStyle(props)} className="material-icons-round">
        mode_edit
    </span>
)
export const CircelOutlinedIcon = (props?: IconProps) => (
    <span style={configStyle(props)} className="material-icons-round">
        radio_button_unchecked
    </span>
)

export const UploadIcon = (props?: IconProps) => (
    <span style={configStyle(props)} className="material-icons-outlined">
        file_upload
    </span>
)
export const DeselectIcon = (props?: IconProps) => (
    <span style={configStyle(props)} className="material-icons-outlined">
        deselect
    </span>
)
export const ArrowForwardIcon = (props?: IconProps) => (
    <span style={configStyle(props)} className="material-icons-outlined">
        navigate_next
    </span>
)
interface Type {
    name: string
    className?: string
    color?: string
    size?: string
}

export const BaseIcon = ({
    name,
    className = 'material-icons-round',
    color = theme.colors.accent,
    size = '18px',
}: Type) => {
    return (
        <span className={className} style={{ color: color, fontSize: size }}>
            {name}
        </span>
    )
}
