import OutlineButton from '@/components/atoms/Button/OutlineButton'
import ArrowClockwiseIcon from '@/components/atoms/Icon/svg/ArrowClockwiseIcon'
import CheckedIcon from '@/components/atoms/Icon/svg/CheckedIcon'
import ExpandIcon from '@/components/atoms/Icon/svg/ExpandIcon'
import SquareFillIcon from '@/components/atoms/Icon/svg/SquareFillIcon'
import SquareFillIconWhite from '@/components/atoms/Icon/svg/SquareFillIconWhite'
import SearchBar from '@/components/atoms/SearchBar'
import theme from '@/theme'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

const useStyle = makeStyles({
    form: {
        width: 'fit-content',
        margin: '0 auto',
    },
    label: {
        display: 'block',
        fontSize: 14,
        fontWeight: 600,
        color: '#000',
        marginBottom: 5,
    },
    siteSelectWrapper: {
        padding: 20,
        backgroundColor: theme.colors.lightGray,
    },
    searchWrapper: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 20,
    },
    checkboxWrapper: {
        marginTop: 20,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    showMore: {
        marginTop: 20,
        padding: '0 20px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: 10,
        cursor: 'pointer',
        textDecoration: 'underline',
        '& > svg': {
            transform: 'scale(0.8)',
        },
    },
    nameSite: {
        fontSize: 12,
        color: '#000000',
    },
})

interface ICategorySelector {
    search: string
    setSearch: React.Dispatch<React.SetStateAction<string>>
    handleReset: () => void
    listData: {
        id: number
        name: string
    }[]
    sites: number[]
    handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    defaultLimitValue: number
    styleShowMore?: string
    styleResetLimit?: string
}

const ManyCategorySelector = (props: ICategorySelector) => {
    const {
        search,
        setSearch,
        handleReset,
        listData,
        sites,
        handleCheckboxChange,
        defaultLimitValue,
        styleShowMore,
        styleResetLimit,
    } = props
    const [limit, setLimit] = useState(defaultLimitValue)
    const showMoreBtn = limit < listData.length
    const resetShow = limit > defaultLimitValue
    const classes = useStyle()

    return (
        <div className={classes.siteSelectWrapper}>
            <div className={classes.searchWrapper}>
                <SearchBar value={search} setValue={setSearch} width={385} placeholder="拠点名で検索" />
                <OutlineButton style={{ width: 134, height: 38, fontSize: 16, fontWeight: 400 }} onClick={handleReset}>
                    選択解除
                </OutlineButton>
            </div>
            <div className={classes.checkboxWrapper}>
                {listData.slice(0, limit).map((site) => {
                    return (
                        <FormControlLabel
                            key={site.id}
                            control={
                                <Checkbox
                                    checked={sites.includes(site.id)}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                        style: { border: '1px solid #222222' },
                                    }}
                                    name={`${site.id}`}
                                    value={site.id}
                                    onChange={handleCheckboxChange}
                                    icon={
                                        site.id !== 0 && sites.includes(0) ? (
                                            <SquareFillIcon />
                                        ) : (
                                            <SquareFillIconWhite />
                                        )
                                    }
                                    checkedIcon={<CheckedIcon />}
                                    color="primary"
                                    disabled={site.id !== 0 && sites.includes(0)}
                                />
                            }
                            label={<span className={classes.nameSite}>{site.name}</span>}
                        />
                    )
                })}
            </div>
            {showMoreBtn && (
                <div onClick={() => setLimit((pre) => pre + 10)} className={styleShowMore || classes.showMore}>
                    次の10件を表示する <ExpandIcon direction="down" />
                </div>
            )}
            {resetShow && (
                <div onClick={() => setLimit(defaultLimitValue)} className={styleResetLimit || classes.showMore}>
                    <ArrowClockwiseIcon /> 最初の表示に戻す
                </div>
            )}
        </div>
    )
}

export default ManyCategorySelector
