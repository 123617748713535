import React from 'react'

export default function SquareFillIconWhite() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Rectangle_4081" data-name="Rectangle 4081" fill="#fff" stroke="#bdbfc8" strokeWidth="1">
                <rect width="24" height="24" rx="3" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" rx="2.5" fill="none" />
            </g>
        </svg>
    )
}
