import { CircelOutlinedIcon } from '@/components/atoms/Icon'
import DashIcon from '@/components/atoms/Icon/svg/DashIcon'
import { ROLE } from '@/constant/role'
import theme from '@/theme'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles'
import React from 'react'
const LegendTableCell = withStyles({
    root: {
        borderBottom: 'none',
    },
})(TableCell)

const useStyles = makeStyles({
    headerCell: {
        fontWeight: 600,
        fontSize: 14,
        padding: '10px 16px',
        borderRight: `3px solid ${theme.colors.white}`,
        '&:not(:first-child)': {
            backgroundColor: theme.colors.primaryN,
            color: theme.colors.white,
        },
    },
    bodyCell: {
        fontWeight: 500,
        fontSize: 14,
        padding: '10px 16px',
        borderBottom: `1px solid ${theme.colors.border}`,
        color: theme.colors.primaryN,
    },
    bodyFirstCell: {
        backgroundColor: theme.colors.expandBackground,
        borderRight: `3px solid ${theme.colors.white}`,
        borderBottom: `3px solid ${theme.colors.white}`,
    },
    desc: {
        fontSize: 10,
        fontWeight: 300,
    },
    note: {
        marginTop: 15,
        fontSize: 10,
        fontWeight: 300,
        color: theme.colors.primaryN,
    },
})

function RoleDetailsTable() {
    const classes = useStyles()

    const headers = [
        { text: '', width: 320 },
        { text: '管理者', width: 126 },
        { text: '閲覧者', width: 126 },
        { text: '一般', width: 126 },
    ]

    const data = [
        {
            id: 1,
            text: (
                <>
                    ユーザー一覧
                    <br />
                    （ユーザー追加、削除、編集、権限設定
                    <span style={{ fontSize: 10 }}>※1</span>）
                </>
            ),
            role: [ROLE.admin],
        },
        { id: 2, text: '企業情報管理（目標設定、拠点名変更）', role: [ROLE.admin] },
        { id: 3, text: 'プラン管理', role: [ROLE.admin] },
        { id: 4, text: 'データ入力', role: [ROLE.admin, ROLE.general], desc: '※入力拠点のみ' },
        { id: 5, text: 'データ分析', role: [ROLE.admin, ROLE.viewer, ROLE.general], desc: '※入力拠点のみ' },
        {
            id: 6,
            text: 'レポーティング <br/>（温対法、省エネ法）',
            role: [ROLE.admin, ROLE.viewer, ROLE.general],
            desc: '※入力拠点のみ',
        },
    ]

    return (
        <TableContainer>
            <Table>
                <TableHead style={{ whiteSpace: 'nowrap' }}>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell
                                width={header.width}
                                className={classes.headerCell}
                                key={header.text}
                                align="center"
                            >
                                {header.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index: number) => (
                        <TableRow key={`${index}-${item.text}`}>
                            <LegendTableCell className={`${classes.bodyCell} ${classes.bodyFirstCell}`}>
                                {typeof item.text === 'string' ? (
                                    <span dangerouslySetInnerHTML={{ __html: item.text }} />
                                ) : (
                                    item.text
                                )}
                            </LegendTableCell>
                            <LegendTableCell className={classes.bodyCell} align="center">
                                {item.role.includes(ROLE.admin) ? <CircelOutlinedIcon /> : <DashIcon />}
                            </LegendTableCell>
                            <LegendTableCell className={classes.bodyCell} align="center">
                                {item.role.includes(ROLE.viewer) ? <CircelOutlinedIcon /> : <DashIcon />}
                            </LegendTableCell>
                            <LegendTableCell className={classes.bodyCell} align="center">
                                {item.role.includes(ROLE.general) ? <CircelOutlinedIcon /> : <DashIcon />}
                                {item.desc && <div className={classes.desc}>{item.desc}</div>}
                            </LegendTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className={classes.note}>
                ※1
                自分自身の権限を変更することはできません。変更する場合は、他のユーザーに管理者権限を付与した上で変更してもらう必要があります。
            </div>
        </TableContainer>
    )
}

export default RoleDetailsTable
