import OutlineButton from '@/components/atoms/Button/OutlineButton'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import CustomModal from '@/components/atoms/CustomModal'
import ArrowClockwiseIcon from '@/components/atoms/Icon/svg/ArrowClockwiseIcon'
import CheckedIcon from '@/components/atoms/Icon/svg/CheckedIcon'
import CircleIcon from '@/components/atoms/Icon/svg/CircleIcon'
import ExpandIcon from '@/components/atoms/Icon/svg/ExpandIcon'
import InfoIcon from '@/components/atoms/Icon/svg/InfoIcon'
import RecordIcon from '@/components/atoms/Icon/svg/RecordIcon'
import SquareFillIcon from '@/components/atoms/Icon/svg/SquareFillIcon'
import InputField from '@/components/atoms/InputField'
import SearchBar from '@/components/atoms/SearchBar'
import SelectField, { MenuItemProps } from '@/components/atoms/SelectField'
import ManyCategorySelector from '@/components/organisms/CategorySelector/many-select'
import { withLayout } from '@/components/template/Layout'
import NotifyValidateEmail from '@/components/template/Management/Account/NotifyValidateEmail'
import RoleDetailsTable from '@/components/template/Management/Account/RoleDetailsTable'
import { ROLE_OPTIONS } from '@/constant/options'
import { ROLE } from '@/constant/role'
import Color from '@/enums/Color'
import { userApi } from '@/ghgApi'
import { UserCreate } from '@/openapi'
import { ROUTES } from '@/routes'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    makeStyles,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useState, useCallback } from 'react'

const useStyle = makeStyles({
    form: {
        width: 'fit-content',
        margin: '0 auto',
    },
    inputField: {
        width: 560,
        margin: '20px auto 0',
        '& .label-wrapper': {
            display: 'flex',
            alignItems: 'center',
            columnGap: 15,
            '& .info-btn': {
                padding: 2,
            },
        },
        '& .label': {
            fontSize: 14,
            fontWeight: 600,
            color: '#000',
        },
        '& .email-field': {
            margin: '10px 0 0 10px',
            fontSize: 14,
            color: theme.colors.greyText,
        },
        '& .radio-select': {
            marginLeft: 20,
            width: 'calc(100% - 20px)',
        },
    },
    btnGroup: {
        marginTop: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 30,
    },
    modalContent: {
        padding: '50px 100px 30px',
    },
    modalBtn: {
        marginTop: 40,
        textAlign: 'center',
    },
    label: {
        display: 'block',
        fontSize: 14,
        fontWeight: 600,
        color: '#000',
        marginBottom: 5,
    },
    siteSelectWrapper: {
        padding: 20,
        backgroundColor: theme.colors.lightGray,
    },
    searchWrapper: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 20,
    },
    checkboxWrapper: {
        marginTop: 20,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    showMore: {
        marginTop: 10,
        padding: '0 20px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: 10,
        cursor: 'pointer',
        textDecoration: 'underline',
        '& > svg': {
            transform: 'scale(0.8)',
        },
    },
    sitesView: {
        minHeight: 102,
        border: '1px solid #BDBFC8',
        borderRadius: 5,
        padding: 10,
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: 10,
        rowGap: 5,
    },
    siteViewItem: {
        padding: 5,
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        fontWeight: 300,
        fontSize: 16,
        borderRadius: 5,
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        columnGap: 20,
    },
    inputEmail: {
        color: Color.greyText,
    },
})

type TUserForm = Pick<UserCreate, 'email' | 'name'> & {
    sites: number[]
    radio: null | '0' | '1'
    siteRef: number | ''
    roleId: number | ''
}

function AddEditAccountForm() {
    const initialValue: TUserForm = {
        email: '',
        name: '',
        roleId: '',
        radio: null,
        siteRef: '',
        sites: [],
    }
    const [inputs, setInputs] = useState<TUserForm>(initialValue)
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [open, setOpen] = useState(false)
    const [typeModal, setTypeModal] = useState('')
    const classes = useStyle()
    const { storeState, managementUser: user, setManagementUser: setUser, setMessage, setStoreState } = useStore()

    const siteOptions = useMemo(() => {
        const options: MenuItemProps[] = storeState.sites.map((site) => ({ id: site.id, value: site.name }))
        return options
    }, [storeState.sites])

    const validateEmail = (email: string) => {
        if (user) return false //when edit can't change email
        if (email === '') return true
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return !regex.test(email)
    }

    const handleValidateFrom = useCallback(
        (inputs: TUserForm) => {
            if (inputs.name === '' || validateEmail(inputs.email) || (inputs.roleId === 2 && inputs.sites.length === 0))
                setIsDisabledSubmit(true)
            else setIsDisabledSubmit(false)
        },
        [inputs],
    )

    useEffect(() => {
        if (user) {
            const sites = user.userSites?.length === siteOptions.length ? [0] : user.userSites?.map((site) => site.id)
            setInputs({
                email: user.email,
                name: user.name,
                roleId: user.roleId,
                radio: user.siteRef ? '1' : '0',
                siteRef: user.siteRef || '',
                sites: sites || [],
            })
        }
    }, [])

    useEffect(() => () => setUser(null), [])

    useEffect(() => {
        handleValidateFrom(inputs)
    }, [inputs])

    const handleChange = (e: React.ChangeEvent<any>) => {
        setInputs((values) => {
            if (e.target.name === 'radio' && e.target.value === '0')
                return { ...values, [e.target.name]: e.target.value }
            return { ...values, [e.target.name]: e.target.value }
        })
    }
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value)
        let newSites = inputs.sites
        if (newSites.includes(value)) {
            newSites = newSites.filter((site) => site !== value)
        } else {
            if (value === 0) {
                newSites = [value]
            } else {
                newSites.push(value)
            }
        }
        setInputs((values) => ({ ...values, sites: newSites }))
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        if (typeof inputs.roleId !== 'number') return
        if (inputs.radio === '1' && inputs.siteRef === '') return
        if (inputs.roleId === ROLE.general && !inputs.sites.length) return

        const userSites =
            inputs.sites.includes(0) || inputs.sites.length === storeState.organization?.siteNumber
                ? undefined
                : inputs.sites
        const siteRef = inputs.radio === '1' && inputs.siteRef ? inputs.siteRef : undefined
        const userInfo = {
            name: inputs.name,
            email: inputs.email,
            roleId: inputs.roleId,
            siteRef,
            userSites: inputs.roleId !== ROLE.general ? undefined : userSites,
        }
        // edit mode
        if (user) {
            try {
                const res = await userApi.updateUser(user.id, userInfo)
                if (res.data.id === storeState.user?.id) {
                    setStoreState({ ...storeState, user: res.data })
                }
                setMessage({ message: '更新に成功しました', type: 'success' })
                navigate(ROUTES.ACCOUNTS_SETTING)
            } catch (err) {
                setMessage({ message: '更新に失敗しました', type: 'error' })
            }
        }
        // add mode
        else {
            try {
                const res = await userApi.createUser(userInfo)
                setMessage({ message: '新規ユーザー作成に成功しました。', type: 'success' })
                navigate(ROUTES.ACCOUNTS_SETTING)
            } catch (err) {
                setMessage({ message: '新規ユーザー作成に失敗しました。', type: 'error' })
            }
        }
    }

    const handleCloseModal = () => setOpen(false)
    const handleResetCheckbox = () => setInputs((pre) => ({ ...pre, sites: [] }))
    const handleDeleteSite = (siteId: number) => {
        const newSite = inputs.sites.filter((s) => s !== siteId)
        setInputs((pre) => ({ ...pre, sites: newSite }))
    }

    const listSites = useMemo(() => {
        if (!storeState.organization?.id) return []

        let listSites = [
            {
                id: 0,
                name: '全ての拠点',
                organizationId: storeState.organization.id,
            },
            ...storeState.sites,
        ]
        if (searchTerm) {
            const search = searchTerm.toLowerCase().trim()
            listSites = listSites.filter((site) => site.name.toLowerCase().includes(search))
        }
        return listSites
    }, [searchTerm, storeState.sites, storeState.organization?.id])

    const contentModal = (type: string) => {
        switch (type) {
            case 'validate-email':
                return {
                    title: 'メールアドレスの変更について',
                    body: <NotifyValidateEmail />,
                }
            default:
                return {
                    title: '編集権限一覧',
                    body: <RoleDetailsTable />,
                }
        }
    }

    return (
        <>
            <CustomModal title={contentModal(typeModal).title} width={960} open={open} onClose={handleCloseModal}>
                <div className={classes.modalContent}>
                    {contentModal(typeModal).body}
                    <div className={classes.modalBtn}>
                        <OutlineButton
                            style={{ width: 120, height: 35, fontSize: 14, fontWeight: 300 }}
                            onClick={handleCloseModal}
                        >
                            閉じる
                        </OutlineButton>
                    </div>
                </div>
            </CustomModal>
            <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.inputField}>
                    <InputField
                        error={inputs.name === ''}
                        inputLabel="氏名"
                        name="name"
                        value={inputs.name}
                        onChange={handleChange}
                        placeholder="氏名を入力"
                    />
                </div>
                <div className={classes.inputField}>
                    {user ? (
                        <div>
                            <div className="label-wrapper">
                                <InputLabel className="label">メールアドレス</InputLabel>
                                <IconButton
                                    className="info-btn"
                                    onClick={() => {
                                        setTypeModal('validate-email')
                                        setOpen(true)
                                    }}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </div>
                            <div className="email-field">{user.email}</div>
                        </div>
                    ) : (
                        <InputField
                            error={inputs.email === ''}
                            inputLabel="メールアドレス"
                            name="email"
                            value={inputs.email}
                            onChange={handleChange}
                            placeholder="メールアドレスを入力"
                            InputProps={{
                                readOnly: user ? true : false,
                                className: user ? classes.inputEmail : '',
                            }}
                        />
                    )}
                </div>
                <div className={classes.inputField}>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <label className={classes.label}>所属</label>
                        <RadioGroup aria-label="gender" name="radio" value={inputs.radio} onChange={handleChange}>
                            <FormControlLabel
                                value="1"
                                control={<Radio color="primary" icon={<CircleIcon />} checkedIcon={<RecordIcon />} />}
                                label="選択する"
                            />
                            <SelectField
                                name="siteRef"
                                size="sm"
                                menuItems={siteOptions}
                                value={inputs.siteRef}
                                onChange={handleChange}
                                placeholder="所属を選択"
                                className="radio-select"
                                disabled={['0', null].includes(inputs.radio)}
                                style={{ pointerEvents: ['0', null].includes(inputs.radio) ? 'none' : 'auto' }}
                            />
                            <FormControlLabel
                                value="0"
                                control={<Radio color="primary" icon={<CircleIcon />} checkedIcon={<RecordIcon />} />}
                                label="選択しない"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={classes.inputField}>
                    <SelectField
                        inputLabel={
                            <span>
                                権限種別 &nbsp;
                                <IconButton
                                    style={{ padding: 0 }}
                                    onClick={() => {
                                        setTypeModal('')
                                        setOpen(true)
                                    }}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </span>
                        }
                        name="roleId"
                        size="sm"
                        menuItems={ROLE_OPTIONS}
                        value={inputs.roleId}
                        placeholder="権限種別を選択"
                        onChange={handleChange}
                        error={inputs.roleId === 2 && inputs.sites.length === 0}
                        disabled={user?.id === storeState.user?.id}
                        style={{ pointerEvents: user?.id === storeState.user?.id ? 'none' : 'auto' }}
                    />
                </div>
                {inputs.roleId === ROLE.general && (
                    <div className={classes.inputField}>
                        <label className={classes.label}>入力拠点</label>
                        <ManyCategorySelector
                            search={searchTerm}
                            setSearch={setSearchTerm}
                            handleReset={handleResetCheckbox}
                            listData={listSites}
                            sites={inputs.sites}
                            defaultLimitValue={20}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                        <label className={classes.label} style={{ marginTop: 20 }}>
                            選択中の拠点
                        </label>
                        <div className={classes.sitesView}>
                            {inputs.sites.map((siteId) => {
                                const site = [{ id: 0, value: '全ての拠点' }, ...siteOptions].find(
                                    (site) => site.id === siteId,
                                )
                                return (
                                    <span key={site?.id} className={classes.siteViewItem}>
                                        {site?.value}
                                        <IconButton
                                            style={{ padding: 0, color: theme.colors.white }}
                                            onClick={() => handleDeleteSite(siteId)}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )}
                <div className={classes.btnGroup}>
                    <OutlineButton
                        style={{ fontSize: 18, fontWeight: 300, width: 480, height: 45 }}
                        onClick={() => navigate(ROUTES.ACCOUNTS_SETTING)}
                    >
                        キャンセル
                    </OutlineButton>
                    <PrimaryButton
                        width={480}
                        height={45}
                        fontSize={18}
                        fontWeight={300}
                        type="submit"
                        disabled={isDisabledSubmit}
                    >
                        {user ? '変更する' : '追加する'}
                    </PrimaryButton>
                </div>
            </form>
        </>
    )
}

export default withLayout(AddEditAccountForm)
